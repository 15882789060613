<template>
  <div class="herbariumList">
    <div v-if="items.length" class="table-wrap">
      <b-table hover :items="items" :fields="fields">
        <template #head()="data">
          <div>
            <span>{{ $t(data.label) }}</span>
          </div>
        </template>
        <template v-slot:cell(image)="row">
          <b-icon
            icon="image"
            variant="success"
            class="icon icon-position"
            @mouseover="showImage = row.value"
            @mouseleave="showImage = ''"
          />
        </template>
        <template v-slot:cell(plant)="row">
          <span>{{ row.value.laName }}</span>
        </template>
        <template v-slot:cell(show)="row">
          <b-icon
            icon="view-list"
            variant="success"
            class="icon"
            @click="goTodetail(row.item.id)"
          />
        </template>
      </b-table>
    </div>
    <div v-if="loading && !items.length" class="pb-5">
      <div class="m-auto">
        <b-spinner variant="success" label="Text Centered"></b-spinner>
      </div>
    </div>
    <div v-if="!items.length" class="text-center">
      <h2 class="MainPageContentNoData mt-5">
        {{ $t('main_page_no_herbarium') }}
      </h2>
    </div>
    <div v-if="!items.length && $route.query" class="text-center">
      <h2 class="MainPageContentNoData mt-5">
        {{ $t('main_page_no_search') }}
      </h2>
    </div>
    <div v-show="showImage">
      <b-img
        thumbnail
        :src="showImage"
        alt="Image Herbarium"
        class="imageShow"
      ></b-img>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    showImage: '',
    fields: [
      {
        key: 'image',
        thClass: 'thead_',
        label: 'main_page_herbarium_list_photo'
      },
      {
        key: 'ruName',
        thClass: 'thead_',
        label: 'main_page_herbarium_list_ruName'
      },
      {
        key: 'enName',
        thClass: 'thead_',
        label: 'main_page_herbarium_list_enName'
      },
      {
        key: 'plant',
        thClass: 'thead_',
        label: 'main_page_herbarium_list_plantName'
      },
      {
        key: 'collectedDate',
        thClass: 'thead_',
        label: 'main_page_herbarium_list_collectedDate'
      },
      {
        key: 'show',
        thClass: 'thead_',
        label: 'main_page_herbarium_list_view'
      }
    ]
  }),
  methods: {
    goTodetail(itemId) {
      this.$router
        .push({ name: 'herbariumItem', params: { id: itemId } })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 2rem;
  cursor: pointer;
}

.icon-position {
  position: relative;
}
.imageShow {
  position: absolute;
  top: 15rem;
  left: 50%;
  z-index: 10;
  max-height: 300px;
  width: auto;
  transform: translateX(-50%);
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.herbariumList {
  padding: 20px 0;
  /deep/.thead_ {
    background: #f6f6f6;
    color: #373a3c;
    font-size: 0.8125rem;
    border: none;
    vertical-align: middle;
    border-radius: 4px 4px 0px 0px;
    @media (max-width: 426px) {
      line-height: 1.5;
    }
  }
  /deep/.table-wrap {
    display: flex;
    flex-direction: column;
  }
  /deep/.input-group {
    color: #ababab;
    input {
      border-left: none;
    }
    .input-group-text {
      border: none;
      background: #f7f7f7;
      padding: 0.375rem 0.2rem 0.375rem 0.75rem;
    }
  }

  /deep/.table > tbody > tr > td {
    border-top: none;
    border-bottom: 1px solid #f1f1f1;
    text-align: center;
    font-size: 0.85rem;
    vertical-align: middle;
    background: #ffffff;
  }
}
</style>
